<template>
	<div>
		<div class="sideBar">
			<div class="go-back-container">
				<a @click="goBack" href="javascript:void(0)" class="go-back">
					<img src="../assets/icons/left-arrow.svg" />
					<span>Zurück</span>
				</a>
			</div>
			<h1 class="site-name">Editor</h1>
			<h3 class="pages">Seiten</h3>
			<PageSelector
				v-model="selectedPageID"
				:project="project"
				:survey="survey"
			/>
		</div>
		<div class="content">
			<div v-if="page != null" class="row">
				<PageDeleteModal
					:projectID="project.id"
					:surveyID="survey.id"
					:pageID="page.id"
					ref="deletePage"
				/>

				<div style="margin-right: 20px" class="col">
					<h1 class="page-id">Seite {{ pageIndex + 1 }}</h1>
					<div class="page-editor">
						<div class="page-name">
							<h3 class="title">Titel</h3>
							<input type="text" v-model="page.title" @input="modifyPageData" />
						</div>
						<div class="page-description">
							<h3 class="title">
								Beschreibung
								<h4 class="subtitle">optional</h4>
							</h3>
							<LiveEditor
								class="editor"
								v-model="page.data"
								:project="project"
								:survey="survey"
								:page="page"
							/>
						</div>
						<hr />
						<ElementSelector
							v-if="page.elementType == null || page.elementType == 0"
							@add="addSelection"
							v-model="page.elementType"
							:project="project"
							:page="page"
							:survey="survey"
						/>
						<SelectionElement
							v-if="page.elementType == 1"
							@delete="removeSelection"
							:project="project"
							:page="page"
							:survey="survey"
						/>
						<CheckboxElement
							v-if="page.elementType == 2"
							@delete="removeSelection"
							:project="project"
							:page="page"
							:survey="survey"
						/>
						<TextboxElement
							v-if="page.elementType == 3"
							@delete="removeSelection"
							:project="project"
							:page="page"
							:survey="survey"
						/>
						<button @click="showDeletePageModal" class="delete-page">
							<img src="../assets/icons/delete.svg" />
							<span>Seite löschen</span>
						</button>
					</div>
				</div>
				<div class="col">
					<LiveDisplay :project="project" :survey="survey" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
	name: 'Editor',
	components: {
		PageSelector: () => import('../components/editor/PageSelector'),
		LiveEditor: () => import('../components/editor/LiveEditor'),
		LiveDisplay: () => import('../components/editor/LiveDisplay'),

		ElementSelector: () => import('../components/editor/ElementSelector'),
		SelectionElement: () => import('../components/editor/SelectionElement'),
		CheckboxElement: () => import('../components/editor/CheckboxElement'),
		TextboxElement: () => import('../components/editor/TextboxElement'),

		PageDeleteModal: () =>
			import('../components/editor/modale/page/PageDeleteModal')
	},
	data: function () {
		return {
			selectedPageID: null
		};
	},
	computed: {
		...mapGetters(['projects']),
		project: function () {
			return this.projects.find((x) => x.id == this.$route.params.projectID);
		},
		survey: function () {
			return this.project.surveys.find(
				(x) => x.id == this.$route.params.surveyID
			);
		},
		page: function () {
			return this.survey.pages.find((x) => x.id == this.selectedPageID);
		},
		pageIndex: function () {
			return this.survey.pages.findIndex((x) => x.id == this.selectedPageID);
		}
	},
	watch: {
		page: function () {
			if (
				this.page != undefined &&
				this.page.elementType != 0 &&
				(this.page.elementData == null || this.page.elementData.length == 0)
			)
				this.getElementsByPage({
					projectID: this.project.id,
					surveyID: this.survey.id,
					pageID: this.page.id
				});
		}
	},
	methods: {
		...mapActions([
			'initElement',
			'deleteSelection',
			'changePageData',
			'getPagesBySurvey',
			'getElementsByPage'
		]),
		goBack: function () {
			this.$router.push({
				name: 'Projekte',
				params: { projectID: this.project.id }
			});
		},
		addSelection: function () {
			this.initElement({
				projectID: this.project.id,
				surveyID: this.survey.id,
				pageID: this.page.id,
				elementType: this.page.elementType
			});
		},
		removeSelection: function () {
			this.deleteSelection({
				projectID: this.project.id,
				surveyID: this.survey.id,
				pageID: this.page.id
			});
		},
		showDeletePageModal() {
			this.$refs.deletePage.show();
		},
		modifyPageData: debounce(function () {
			this.changePageData({ pageID: this.page.id, title: this.page.title });
		}, 400)
	},
	mounted: function () {
		if (this.$route.params.projectID == null)
			this.$router.push({ name: 'Projekte' });

		if (this.survey != undefined && this.survey.pages.length == 0)
			this.getPagesBySurvey({
				projectID: this.project.id,
				surveyID: this.survey.id
			});

		setInterval(() => {
			// eslint-disable-next-line no-console
			console.log(JSON.stringify(this.project));
		}, 10000);
	}
};
</script>

<style lang="scss" scoped>
.sideBar {
	width: 200px;
	height: 100%;

	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;

	padding: 0 20px 0 20px;

	background-color: $unnamed-color-1a1a1a;
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	.go-back-container {
		margin-top: 40px;

		.go-back {
			height: 19px;
			text-decoration: none;

			@include flex-left;

			span {
				@include bold-text-uppercase-15;
				color: $unnamed-color-999999;
			}

			img {
				width: 12px;
				height: 12px;
				margin-right: 9px;
			}
		}
	}

	.site-name {
		margin-top: 8px;
		margin-bottom: 40px;

		@include bold-text-uppercase-35;
		color: $unnamed-color-ffffff;
	}

	.pages {
		@include bold-text-uppercase-22;
		color: $unnamed-color-999999;
	}
}

.content {
	display: block;
	position: absolute;
	height: auto;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	margin-left: 200px;
	background-color: green;
	padding: 67px 40px;

	background-color: $unnamed-color-000000;
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	.col {
		flex: 1;
	}

	.row {
		display: flex;

		hr {
			margin: 40px 0 40px 0;
		}

		.delete-page {
			height: 48px;
			width: 100%;

			margin-bottom: 40px;

			padding-right: 20px;

			position: relative;
			text-align: center;

			background: #321308 0% 0% no-repeat padding-box;
			border-radius: 4px;
			opacity: 1;
			border: none;

			cursor: pointer;
			color: #fc6027;

			span {
				@include normal-text;
				color: inherit;
				font-size: 22px;
				font-weight: bold;
				text-transform: uppercase;
			}

			img {
				width: 24px;
				height: 24px;

				position: absolute;
				left: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.page-id {
			margin-top: 0;
			margin-bottom: 0;

			@include bold-text-uppercase-35;
			color: $unnamed-color-ffffff;
		}

		.page-editor {
			margin-top: 40px;

			.title {
				margin-bottom: 8px;

				@include normal-text-22;
				color: $unnamed-color-999999;

				.subtitle {
					display: inline-block;
					margin-left: 20px;

					@include normal-text-15;
					color: $unnamed-color-999999;
				}
			}

			.page-name {
				margin-bottom: 20px;

				input {
					width: 100%;
					height: 48px;

					padding: 0 16px;

					border: 1px solid $unnamed-color-999999;
					background: #262626 0% 0% no-repeat padding-box;
					border-radius: 4px;

					@include normal-text-22;
					color: $unnamed-color-ffffff;
				}
			}

			.page-description {
				.editor {
					padding: 20px;

					border: 1px solid $unnamed-color-999999;
					background: #262626 0% 0% no-repeat padding-box;
					border-radius: 4px;
				}
			}
		}
	}
}
</style>
